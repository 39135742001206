import qs from 'qs'

const getSearchFromLocation = () => {
  if (typeof window !== 'undefined') {
    const queries = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    return queries
  }
  return {}
}

export default getSearchFromLocation
